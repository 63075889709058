<template>
    <BT-Dialog
        :buttonClass="buttonClass"
        icon="mdi-source-branch-sync"
        :small="small"
        title="Xero Mappings"
        width="450"
        okText=""
        cancelText=""
        :persistent="false">
        <template>
            <BT-Mappings
                :externalParty="externalParty"
                :externalPropertyOptions="externalPropertyOptions"
                :externalPropertyOptionSet="externalPropertyOptionSet"
                :isEditing="isEditing"
                :itemType="itemType"
                :itemID="id"
                :navigation="navigation" />

        </template>
    </BT-Dialog>
</template>

<script> 
export default {
    name: 'BT-Mappings-Dialog',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTMappings: () => import('~components/BT-Mappings.vue')
    },
    data: function() {
        return {
            id: null
        }
    },
    props: {
        buttonClass: {
            type: String,
            default: null
        },
        navigation: null,
        isEditing: {
            type: Boolean,
            default: false
        },
        itemType: {
            type: String,
            default: 'Product'
        },
        externalParty: {
            type: String,
            default: 'xero'
        },
        itemID: {
            type: String,
            default: null
        },
        item: {
            type: Object,
            default: null
        },
        externalPropertyOptions: {
            type: Array,
            default: null
        },
        externalPropertyOptionSet: {
            type: String,
            default: 'xero'
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.id = this.itemID;
    },
    watch: {
        itemID: function(val) {
            this.id = val;
        },
        item: function(val) {
            this.id = val.id;
        }
    }
}
</script>